import { ReactNode } from 'react'
import { customerLogos } from './customers'

export type Quote = {
  cite: string
  quote: string | ReactNode
  logo?: string
  cta?: string
  ctaLabel?: string
}

export const QuoteAscend: Quote = {
  cite: 'Shyam Sivakumar, Head of Data',
  logo: customerLogos.ascend,
  quote: `When you introduce a tool with better performance and visualizations, and a more intuitive user experience, I find you usually see a step change in adoption across the organization, and that's been our experience with Omni.`,
  cta: '/blog/case-study-ascend',
}

export const QuoteAviatrix: Quote = {
  cite: 'Cody Pulliam, Senior Manager of Business Analytics, Aviatrix',
  logo: customerLogos.aviatrix,
  quote: `Compared to Tableau and Looker, Omni was by far the best performer in terms of all-around functionality.`,
  cta: '/blog/case-study-aviatrix',
}

export const QuoteAviatrix2: Quote = {
  cite: 'Cody Pulliam, Senior Manager of Business Analytics, Aviatrix',
  logo: customerLogos.aviatrix,
  quote: `Despite having a complex model, a large backlog of content, and more than 100 users, we were able to complete the migration to Omni in just three weeks with the help of Omni’s team.`,
  cta: '/blog/case-study-aviatrix',
}

export const QuoteDigitalTrends: Quote = {
  cite: 'Dan Gaul, Chief Technical Officer',
  logo: customerLogos.digitalTrends,
  quote: `Omni fills a big gap for us. We don't need nearly as many in-house
  resources to be successful anymore. Omni is so user-friendly that the
  entire team can access and explore data on their own. After our
  experience with Looker, we've been so pleasantly surprised —
  everyone's saying: 'Wow, this just works.'`,
  cta: '/blog/case-study-digital-trends',
}

export const QuoteTheRounds: Quote = {
  cite: 'Ari Stern, Staff Analytics Engineer',
  logo: customerLogos.theRounds,
  cta: '/blog/case-study-the-rounds',
  quote: `With Omni, our data team now focuses on new projects, while business
  users make faster decisions. This efficiency is crucial for
  sustainable business operations.`,
}

export const QuoteTheRounds2: Quote = {
  cite: 'Griffin Tschurwald, Head of Engineering',
  logo: customerLogos.theRounds,
  cta: '/blog/case-study-the-rounds',
  quote: `We have a lot of people who are Excel power users and they love the
  ability to pivot quickly in Omni.`,
}

export const QuoteSifflet: Quote = {
  cite: 'Martin Zerbib @ Sifflet',
  logo: customerLogos.sifflet,
  quote: `Omni balances the best of Looker's code-based modeling layer approach
  with the best of Tableau's self-service workbooks — empowering a wider
  variety of users to explore and consume without falling into the
  pitfall of complexity creep.`,
  cta: '/blog/case-study-sifflet',
}

export const QuoteSifflet2: Quote = {
  cite: 'Martin Zerbib, Head of Product at Sifflet',
  logo: customerLogos.sifflet,
  quote: `The implementation was incredibly straightforward and very fast. We were able to build a dashboard on the very first day.`,
  cta: '/blog/case-study-sifflet',
}

export const QuoteEbh: Quote = {
  cite: 'Mark Valdez, CEO',
  logo: customerLogos.ebh,
  cta: '/blog/case-study-eads-bridge-holdings',
  quote: `Replacing our previous BI tool with Omni gave us another level of
insight and ease that we really need, internally and for our
companies. Now, we're able to extend that impact across our companies
to help them access data without requiring in-house data experts or
tools.`,
}

export const QuoteEbh2: Quote = {
  cite: 'Mark Valdez, CEO',
  logo: customerLogos.ebh,
  cta: '/blog/case-study-eads-bridge-holdings',
  quote: `Omni put a tremendous amount of effort into ensuring that we were successful. It’s comforting knowing that we have a team behind us that is going to get us where we need to go.`,
}

export const QuoteRoseRocket = {
  cite: 'Jade Khiev, Insights Engineering Lead',
  logo: customerLogos.roseRocket,
  quote: `Redefining our product and go-to-market strategy has resulted in exceptional growth, but it also meant we needed to maximize the flexibility, performance, and ease of data access. By using Omni and Snowflake, our internal users can self-serve and get faster answers. This frees up our technical resources to focus on delivering the best product and customer experience.`,
  cta: '/blog/case-study-rose-rocket',
}

export const QuoteRoseRocket2 = {
  cite: 'Jade Khiev, Insights Engineering Lead',
  logo: customerLogos.roseRocket,
  quote: `It's very intuitive. We've received feedback from many of our co-workers that Omni just feels so much easier than Looker.`,
  cta: '/blog/case-study-rose-rocket',
}
export const QuoteRoseRocket3 = {
  cite: 'Jade Khiev, Insights Engineering Lead',
  logo: customerLogos.roseRocket,
  quote: `Migrating to Omni allowed my team to hand off most of the day-to-day internal analytics needs so we can focus on our platform and building new value for our customers.`,
  cta: '/blog/case-study-rose-rocket',
}

export const QuoteTrint: Quote = {
  cite: 'Anya Osen Barnett, Senior Analytics Engineer',
  logo: customerLogos.trint,
  quote: `I really love Snowflake’s SQL syntax. It’s just more advanced than other dialects of SQL. When you’re trying to duplicate something, you’d normally have to create another CTE, and that ends up being very bulky. With Snowflake, you can just use the QUALIFY clause instead.`,
  cta: '/blog/case-study-trint',
}

export const QuoteTrint2: Quote = {
  cite: 'Anya Osen Barnett, Senior Analytics Engineer',
  logo: customerLogos.trint,
  quote: `We ended up having team members who weren’t previously comfortable using our BI tool ask for access to Omni, and now we have more people actively using BI with Omni than before.`,
  cta: '/blog/case-study-trint',
}

export const QuoteTrint3: Quote = {
  cite: 'Anya Osen Barnett, Senior Analytics Engineer',
  logo: customerLogos.trint,
  quote: `Every time I make a change to the model in dbt, all I need to do is refresh the schema, and the change is reflected in Omni automatically. Before, it was manual. We had a whole section of tickets just reminding us to make the corresponding changes in our previous BI tool.`,
  cta: '/blog/case-study-trint',
}

export const QuoteTrint4: Quote = {
  cite: 'Anya Osen Barnett, Senior Analytics Engineer',
  logo: customerLogos.trint,
  quote: `Omni opens up data exploration to more people on the team. Our previous tool required expertise in SQL or a custom coding language to do calculations, but Omni Calculations use Excel syntax which makes things much easier for people.`,
  cta: '/blog/case-study-trint',
}

export const QuoteSnowflakePartner: Quote = {
  cite: 'Tarik Dwiek, Head of Technology Alliances, Snowflake',
  logo: '/images/partners/snowflake.svg',
  quote: ` Together, Omni and Snowflake help customers deliver scalable, intuitive analytics experiences to their organizations and customers. By letting users query, model, and iterate on data stored in Snowflake with a flexible, familiar UI and features like Calculations AI, Omni can help users answer questions at nearly the speed of thought. We expect this combination to continue freeing up time for data teams to invest their talents in innovative, monetizable product developments.`,
  cta: '/blog/snowflake-invests-in-omni',
  ctaLabel: 'Learn More',
}

export const QuotePrimaryBid: Quote = {
  cite: 'Jonny Dungay, Analytics and BI Lead at PrimaryBid',
  logo: customerLogos.primaryBid,
  quote: `We wanted to give our customers a truly modern analytics experience without needing to invest significant front-end engineering resources to build it. Of the many embedded analytics products we evaluated, Omni was the only one that would make it possible for us to achieve that goal.`,
  cta: '/blog/case-study-primarybid',
}

export const QuoteMiddleware: Quote = {
  cite: 'Zach Michel, Co-founder',
  logo: customerLogos.middleware,
  quote: `We wanted a tool that had all the features we needed in a single platform and would make everyone's life easier. After testing it, choosing Omni was an easy decision.`,
  cta: '/blog/case-study-middleware',
}

export const QuoteIncidentIo: Quote = {
  cite: 'Jack Colsey, Analytics Manager, incident.io',
  logo: customerLogos.incidentio,
  quote: `The transition from custom SQL in Metabase to a semantic layer in Omni was an opportunity to ‘slow down to speed up’. By migrating to Omni, we were able to consolidate the number of dashboards in our BI tool by 5x.`,
  cta: '/blog/case-study-incident-io',
}

export const QuoteIncidentIo2: Quote = {
  cite: 'Jack Colsey, Analytics Manager, incident.io',
  logo: customerLogos.incidentio,
  quote: `The transition from custom SQL in Metabase to a semantic layer in Omni was an opportunity to “slow down to speed up.” By migrating to Omni, we were able to consolidate the number of dashboards in our BI tool by 5x.`,
  cta: '/blog/case-study-incident-io',
}

export const QuoteIncidentIo3: Quote = {
  cite: 'Jack Colsey, Analytics Manager, Incident.io',
  logo: customerLogos.incidentio,
  quote: `We’ve seen really good adoption across the company. Pretty much everyone is involved in Omni in one way or another.`,
  cta: '/blog/case-study-incident-io',
}

export const QuoteUscreen: Quote = {
  cite: 'Joe Horowitz, VP of Growth & Analytics',
  logo: customerLogos.uscreen,
  quote: `We want to own our business logic, so the dbt <> git workflow works well for us. None of the other vendors seemed to offer that flexibility, but Omni’s bi-directional dbt integration really stood out to us as something that aligned with our philosophy. Beyond technical alignment, Omni seemed like the right partner as a company; it was by far the best sales experience I’ve had. I believe in the future with Omni.`,
  cta: '/blog/case-study-uscreen',
}

export const QuoteAura: Quote = {
  cite: 'Setareh Lotfi, CTO',
  logo: customerLogos.aura,
  quote: `A lot of our non-technical users are able to upload data that doesn’t live in our data warehouse; it’s fantastic because they don’t need engineering resources to do their work. As someone who leads engineering, Omni’s helped open up my bandwidth because I don’t have to give up as much engineering time and resources to help people do their jobs.`,
}
